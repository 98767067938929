import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

const IndexPage = ({ pageContext }) => {
  return (
    <Layout>
      <div className="container index">
        <h1 className="align-center">
          Grow your career at Nissan and unlock your talent
        </h1>
        <h2 className="align-center">
          Power your career path by exploring different roles and testing their
          match to your capability and potential.
        </h2>
        <p className="start-heading">How it works</p>
        <ul className="steps">
          <li key="1">
            <div className="icon-circle">
              <div className="icon-circle__number">
                <span>1</span>
              </div>
              <img src={"/flag.svg"} alt="" />
            </div>
            <p className="steps-heading">Select your desired role</p>
            <p className="steps-description">
              Learn and find your desired role at Nissan Australia
            </p>
          </li>
          <li key="2">
            <div className="icon-circle">
              <div className="icon-circle__number">
                <span>2</span>
              </div>
              <img src={"/preferences.svg"} alt="" />
            </div>
            <p className="steps-heading">Self Assess</p>
            <p className="steps-description">
              View the top competencies and assess yourself
            </p>
          </li>
          <li key="3">
            <div className="icon-circle">
              <div className="icon-circle__number">
                <span>3</span>
              </div>
              <img src={"/star-half.svg"} alt="" />
            </div>
            <p className="steps-heading">See results and share</p>
            <p className="steps-description">
              Download the Job Description and share your results
            </p>
          </li>
        </ul>
        <p className="align-center extra-text">
          Each step on your pathway needs to offer you development opportunities
          as well as build on your areas of strength.
        </p>
      </div>
      <div className="buttons-wrapper">
        <Link to="/list" className="next-button full-width">
          Start Now
          <img src={"/right-arrow.svg"} className="icon" />
        </Link>
      </div>
    </Layout>
  )
}

export default IndexPage
